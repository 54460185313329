import {GetterTree} from 'vuex';
import {
  GET_IS_LOADING,
  GET_USER_INFO
} from './getter-types';
import {
  IntUserProfileState
} from './index';

const getters: GetterTree<IntUserProfileState, {}> = {
  [GET_USER_INFO]: state => state.userInfo,
  [GET_IS_LOADING]: state => state.isLoading
}

export default getters;