import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store';

import {
  CHECK_TOKEN
} from '../store/modules/auth/action-types';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-layout" */ "../layout/DashboardLayout.vue"
      ),
    redirect: "/dashboard",
    meta: {
      isProtected: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "maindashboard",
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Home",
              routeName: "maindashboard",
            },
          ],
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/DashboardView.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next)=>{
  if(to.meta && to.meta.isProtected) {
    store.dispatch(CHECK_TOKEN, true)
      .then(() => {
        next();
      });
  } else {
    next();
  }
})

export default router
