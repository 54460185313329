import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import UserProfileStore from './modules/user';
import LoadingState from './modules/loading';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    LoadingState,
    UserProfileStore,
  },
});
