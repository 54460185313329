import {MutationTree} from 'vuex';
import vueApp from '@/main';
import {
  SET_USER_INFO,
  SET_IS_LOADING
} from './mutation-types';

import {
  IntUserProfileState
} from './index';

const mutations: MutationTree<IntUserProfileState> = {
  [SET_USER_INFO](state, payload) {
    state.userInfo = payload;
    //// TODO
    vueApp.$i18n.locale = payload.language ? payload.language.toLocaleLowerCase() : 'en';
  },
  [SET_IS_LOADING](state, payload: boolean) {
    state.isLoading = payload;
  }
};
 export default mutations;