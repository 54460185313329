import Vue from 'vue'
import i18n from './i18n';
import App from './App.vue'
import router from './router'
import store from './store';
import { defineCustomElements } from '@telekom/scale-components/loader';
import '@telekom/scale-components/dist/scale-components/scale-components.css';
import './common/http/interceptors';
import Vuelidate from 'vuelidate';
import VJsoneditor from 'v-jsoneditor';
Vue.use(Vuelidate);
Vue.use(VJsoneditor);

defineCustomElements();
// main.js
Vue.config.ignoredElements = [/scale-\w*/];
import './styles/styles.scss';


defineCustomElements();
Vue.use(Vuelidate);
Vue.config.productionTip = false
Vue.config.ignoredElements = [/scale-\w*/];

const vueApp = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

export default vueApp;
