import {ActionTree} from 'vuex';
import {
  ADD_ERROR_NOTIFICATION
} from '@/components/NotificationBar/store/mutation-types';
import {
  GET_USER_PROFILE_INFO
} from './action-types';
import {
  SET_IS_LOADING,
  SET_USER_INFO
} from './mutation-types';
import UserService from '@/common/services/UserServices';
import {
  IntUserProfileState
} from './index';

const actions: ActionTree<IntUserProfileState, {}> = {

  // get user profile information
  [GET_USER_PROFILE_INFO]({commit}) {
    // return new Promise((resolve, reject) => {
    //   commit(SET_IS_LOADING, true);
    //   UserService.getUserInfo()
    //     .then(res => {
    //       const {status, data} = res;
    //       if(status === 200) {
    //         commit(SET_USER_INFO, data);
    //       }
    //       resolve('success');
    //     })
    //     .catch(() => {
    //       commit(ADD_ERROR_NOTIFICATION, 'genericError', {
    //         root: true
    //       });
    //       reject('error');
    //     })
    //     .finally(() => {
    //       commit(SET_IS_LOADING, true);
    //     });
    // });
    return commit(SET_IS_LOADING, true);
  }
};

export default actions;
